import { getSfProductByHandle } from "~/lib/shopify/queries/get-sf-product-by-handle"

type ProductDataOptions = {
  slug: string
  locale: string
  includeFullDetails?: boolean
  metafieldKeys?: string[]
  options?: Record<string, unknown>
  onError?: (error: { type: "NOT_FOUND" | "ERROR"; stage: "HANDLE" | "PRODUCT" }) => void
}

export async function getMinimalProductDataFromHandle({ slug, locale, options = {} }: ProductDataOptions) {
  // First, get basic product info
  const { productByHandle } = await getSfProductByHandle({
    handle: slug,
    locale,
    // Minimal data for initial fetch
    includePrices: false,
    includeSeo: false,
    includeSelectedOptions: false,
    includeQuantityAvailable: false,
    includeTags: false,
    storeAvailabilityFirst: 0,
    variantsFirst: 0,
    mediaFirst: 0,
    nextTags: [slug],
    ...options,
  })

  return { productByHandle }
}
